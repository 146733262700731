import React, { FC, useContext } from "react";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Table from "@components/common/Table/Table";
import {
	PaymentStatus,
	PaymentStatusName,
} from "@components/common/PaymentStatus";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Heading, Button } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
	ButtonIconsVariant,
} from "@components/ui/Button/Button.props";
import { ProfilePagesTitleEnum } from "@common/types";
import ProfileContainer from "@components/profile/Views/Container/Container";
import clubApi from "@api/clubApi";
import { Spinner } from "react-bootstrap";
import { configureAssetPath, withRootUrl } from "@common/utils";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { useProfileSection } from "@hooks/useProfileSection";
import { SectionsCodeEnum } from "@features/utils/types";
import styles from "./referal.module.scss";
import useWindowSize from "@hooks/useWindowSize";
import { ADAPTIVE_BREAKPOINTS, TOAST_AUTO_HIDE_DELAY } from "@common/constants";
import { formatDate } from "@common/utils";
import ProfileSection from "@components/profile/Section/ProfileSection";
import illustration from "@images/referal.svg";
import ToastErrorMessage from "@components/common/Toast/ToastErrorMessage";

const referalPerson: {
	id: string;
	name: string;
	role: string;
	date: string;
}[] = [
	{
		id: "10324",
		name: "Панфилов Дмитрий Петрович",
		role: "Инвестор",
		date: "2024-08-09T11:12:29.167346+03:00",
	},
	{
		id: "82974",
		name: "Степанченко-Мелявская Василиса Олеговна",
		role: "Заёмщик",
		date: "2024-08-08T12:27:47.508662+03:00",
	},
];

const referalReward: {
	id: string;
	name: string;
	amount: string;
	date: string;
	status: PaymentStatusName;
}[] = [
	{
		id: "10324",
		name: "Панфилов Дмитрий Петрович",
		status: PaymentStatusName.success,
		date: "2024-08-09T11:12:29.167346+03:00",
		amount: "1002.17",
	},
	{
		id: "82974",
		name: "Степанченко-Мелявская Василиса Олеговна",
		status: PaymentStatusName.success,
		date: "2024-08-09T11:12:29.167346+03:00",
		amount: "120.691",
	},
	{
		id: "82974",
		name: "Степанченко-Мелявская Василиса Олеговна",
		status: PaymentStatusName.pending,
		date: "2024-08-08T12:27:47.508662+03:00",
		amount: "78.95",
	},
	{
		id: "82974",
		name: "Степанченко-Мелявская Василиса Олеговна",
		status: PaymentStatusName.pending,
		date: "2024-08-08T12:27:47.508662+03:00",
		amount: "902.012",
	},
	{
		id: "10324",
		name: "Панфилов Дмитрий Петрович",
		status: PaymentStatusName.pending,
		date: "2024-08-08T12:27:47.508662+03:00",
		amount: "102.66",
	},
];

export const ProfileReferal: FC = () => {
	const isLoading = false;
	const referalLink = "lk/panfilovinfest.ru/referal-10845";

	const copied = () => {
		navigator.clipboard
			.writeText(referalLink)
			.then(() => {
				toast.success(<ToastErrorMessage message='Текст скопирован!' />, {
					autoClose: TOAST_AUTO_HIDE_DELAY,
					position: toast.POSITION.TOP_LEFT,
				});
			})
			.catch((err) =>
				toast.error(
					<ToastErrorMessage message='Что-то пошло не так попробуйте снова' />,
					{
						autoClose: TOAST_AUTO_HIDE_DELAY,
						position: toast.POSITION.TOP_LEFT,
					},
				),
			);
	};
	return (
		<ProfileContainer title={ProfilePagesTitleEnum.REFERAL}>
			<ProfileSection title='Увеличивайте свой капитал!' limitMinHeight>
				{isLoading ? (
					<Spinner
						className={"spinner--centered"}
						animation='border'
						variant='primary'
					/>
				) : (
					<div className={styles.container}>
						<div className={styles.blockWithImg}>
							<div className={styles.blockWrap}>
								<div className={styles.textWrap}>
									<p>
										Приглашайте инвесторов и заёмщиков к нам на платформу
										и вы будете получать:
									</p>
									<ul>
										<li>
											— 0,6% от суммы всех сделок займа приглашенного инвестора;
										</li>
										<li>
											— 0,6% от суммы всех выданных займов привлеченного
											заемщика.
										</li>
									</ul>
									<p>
										Вознаграждение переводится на баланс вашего личного кабинета
										один раз в месяц в период действия реферальной программы.
										Если Реферал является физическим лицом, то вознаграждение
										выплачивается за минусом удержанного НДФЛ.
									</p>
									<a href='#' className={styles.link}>
										Подробные условия в оферте
									</a>
								</div>
								<div className={styles.copyWrap}>
									<Heading
										tag={"h3"}
										className={classNames(styles.heading, "mb-0")}
										variant={HeadingVariant.middle}>
										Реферальная ссылка
									</Heading>
									<Button
										variant={ButtonVariant.outlined}
										as={ButtonTypesEnum.BUTTON}
										icon={ButtonIconsVariant.copy}
										className={styles.buttonCopy}
										onClick={copied}>
										{referalLink}
									</Button>
								</div>
							</div>
							<div className={styles.imgWrapper}>
								<img src={illustration} alt='illustration' />
							</div>
						</div>
						<div
							className={classNames(styles.blockItem, styles.blockItemShort)}>
							<Heading
								tag={"h3"}
								className={classNames(styles.heading, "mb-0")}
								variant={HeadingVariant.middle}>
								Список рефералов
							</Heading>
							<Table
								className={styles.table}
								emptyMessage={"Рефералов пока что нет"}
								horizontallyScrolled
								thCells={["Дата регистрации", "ID", "ФИО", "Тип реферала"]}
								tdCells={referalPerson.map(({ date, id, name, role }) => {
									return [
										formatDate(date) || "-",
										id || "-",
										name || "-",
										role || "-",
									];
								})}
							/>
						</div>
						<div className={styles.blockItem}>
							<Heading
								tag={"h3"}
								className={classNames(styles.heading, "mb-0")}
								variant={HeadingVariant.middle}>
								Реферальные вознаграждения
							</Heading>
							<Table
								className={styles.table}
								emptyMessage={"Вознаграждений пока что нет"}
								horizontallyScrolled
								thCells={["Дата", "Сумма", "ID", "ФИО", "Статус перевода"]}
								tdCells={referalReward.map(
									({ date, amount, id, name, status }) => {
										return [
											formatDate(date) || "-",
											amount ? (
												<>
													<NumberFormat
														title={parseInt(amount).toString()}
														value={Number(amount)}
														displayType={"text"}
														thousandSeparator={" "}
														suffix={" "}
														fixedDecimalScale
													/>
													<span> &#x20bd;</span>
												</>
											) : (
												"-"
											),
											id || "-",
											name || "-",
											<PaymentStatus key={id + date}>{status}</PaymentStatus>,
										];
									},
								)}
							/>
						</div>
					</div>
				)}
			</ProfileSection>
		</ProfileContainer>
	);
};
